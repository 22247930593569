import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Avatar, Popover, Button, Modal, Form, Row, Col, message } from "antd";
import msalConfig from "util/msal";
import { UserAgentApplication } from 'msal';
import { userSignOut } from "appRedux/actions/Auth";
import { getUserPhoto } from 'util/GraphService';
import axios from "util/Api";
import { apiUrl } from "util/config";
import { setUser } from "appRedux/actions/Auth";

class UserProfile extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      photo: 'https://via.placeholder.com/150x150',
      updateAvatarModal: {
        show: false,
        loading: false,
        base64Img: null,
        file: null,
      },
    }

    this.userAgentApplication = new UserAgentApplication({
      auth: {
        clientId: msalConfig.auth.clientId
      },
      cache: msalConfig.cache,
    });
  }

  logout = () => {
    this.userAgentApplication.logout();
  }

  setPhoto = async () => {
    try {
      const { avatar } = this.props.authUser;
      let blobPhoto = null;
      if (!avatar) {
        blobPhoto = await getUserPhoto(this.props.token);
      }

      const url = window.URL || window.webkitURL;
      if (this._isMounted) {
        this.setState({
          photo: avatar || url.createObjectURL(blobPhoto)
        });
      }
    } catch(e) {
      //
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.setPhoto();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updatePreview(e) {
    const img = e.target.files[0];

    if (img) {
      const reader = new FileReader();

      reader.readAsDataURL(img);
      reader.onload = _ => {
        this.setState({
          updateAvatarModal: {
            ...this.state.updateAvatarModal,
            file: img,
            base64Img: reader.result,
          },
        });
      };

      reader.onerror = err => {
        console.error(err);
      };
    }
  }

  async updateAvatar(e) {
    e.preventDefault();
    try {
      const hideLoading = message.loading('Subiendo foto de perfil', 0);
      const data = new FormData();

      this.setState({
        updateAvatarModal: {
          ...this.state.updateAvatarModal,
          loading: true,
        },
      });

      data.append('idUSUARIO', this.props.authUser.user_id);
      data.append('foto', this.state.updateAvatarModal.file);

      const url = `${apiUrl}/public/users/${this.props.authUser.user_id}/foto`;
      const res = await axios({ method: 'patch', url, data, headers: { 'Content-Type': 'multipart/form-data'}});
      hideLoading();
      message.success('Foto de perfil subida', 2.5);
      const { authUser } = this.props;
      authUser['avatar'] = res.data.avatar;
      this.props.setUser(authUser);
      this.setState({
        updateAvatarModal: {
          ...this.state.updateAvatarModal,
          show: false,
        },
        photo: res.data.avatar,
        loading: false
      });
      // this.setPhoto();
    } catch(err) {
      this.setState({
        updateAvatarModal: {
          ...this.state.updateAvatarModal,
          show: false,
        },
        loading: false
      });
      message.error('Hubo un error al subir la foto de perfil', 2.5);
    }
  }

  render() {
    const { authUser } = this.props;
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => this.logout()}>Cerrar Session</li>
      </ul>
    );

    const { updateAvatarModal } = this.state;

    const buttonChangeAvatar = (
      <Button
        type="primary" style={{marginBottom: '0'}}
        onClick={_ => this.setState({updateAvatarModal: {
          ...this.state.updateAvatarModal,
          show: true,
        }})}
      >Cambiar avatar</Button>
    )

    const updateAvatarModalRender = (
      <Modal
        width="60%"
        title="Subir nuevo avatar"
        keyboard={false}
        footer={null}
        visible={updateAvatarModal.show}
        closable={false}
        maskClosable={false}
      >
        <Form id="updateAvatarForm" onSubmit={e => this.updateAvatar(e)}>
          <Row style={{marginBottom: '1rem'}}>
            <Col span={24} style={{display: 'flex', justifyContent: 'center'}}>
              <label htmlFor="file-input">
                <div
                  style={{
                    width: '60vh',
                    height: '60vh',
                    border: '1px dashed black',
                    borderRadius: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundImage: 'url(\'' + updateAvatarModal.base64Img + '\')',
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  {
                    !updateAvatarModal.base64Img &&
                    <p>Haz click aquí para seleccionar un nuevo avatar</p>
                  }
                </div>
              </label>
              <input accept=".png,.jpeg,.jpg" id="file-input" type="file" style={{display: 'none'}} onChange={e => this.updatePreview(e)}></input>
            </Col>
          </Row>
          <Row type="flex" justify="end">
            <Col>
              <Button
                type="default"
                onClick={_ => this.setState({updateAvatarModal: {...updateAvatarModal, show: false}, base64Img: null})}
                disabled={updateAvatarModal.loading}
                style={{marginBottom: '0'}}
              >
                Cancelar
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={updateAvatarModal.loading || !updateAvatarModal.file}
                style={{marginBottom: '0'}}
              >
                Guardar
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    )

    return (
      <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="right" content={buttonChangeAvatar} trigger="hover">
        <Avatar src={this.state.photo}
          className="gx-size-100 gx-pointer gx-mr-3 center-profile-avatar" alt=""/>
      </Popover>
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
          <span className="gx-avatar-name center-profile-name">
            {authUser && authUser.displayName ? authUser.displayName : ''}
            <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/>
          </span>
        </Popover>
        {updateAvatarModalRender}
      </div>
    )
  }
}

const mapStateToProps = ({auth}) => {
  const {authUser, token} = auth;
  return {authUser, token}
};

export default withRouter(connect(mapStateToProps, { setUser, userSignOut })(UserProfile));
