import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET
} from "constants/ActionTypes";
import axios from 'util/Api'

export const userSignedIn = (accessToken) => {
  return (dispatch) => {
    localStorage.setItem("token", JSON.stringify(accessToken));
    axios.defaults.headers.common['access-token'] = "Bearer " + accessToken;
    dispatch({type: USER_TOKEN_SET, payload: accessToken});
  }
};

export const setUser = (user) => {
  return (dispatch) => {
    localStorage.setItem("emasal_user", JSON.stringify(user));
    dispatch({type: USER_DATA, payload: user});
  }
};

export const userSignOut = () => {
  return (dispatch) => {
    localStorage.removeItem("token");
    localStorage.removeItem("emasal_user");
    dispatch({type: SIGNOUT_USER_SUCCESS});
  }
};

export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
