import moment from 'moment';
import { isNil, isEmpty } from "ramda";

const helpers = {
  formatDolar: (n) => {
    if (n) {
      return '$ ' + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    } else {
      return '$ 0.00';
    }

  },
  formatDate: (date) => {
    if (date) {
      return moment(date).local().format('DD/MM/YYYY')
    } else {
      return moment().local().format('DD/MM/YYYY');
    }

  },
  formatDateTime: (date) => {
    if (date) {
      return moment(date).local().format('DD/MM/YYYY HH:mm a')
    } else {
      return moment().local().format('DD/MM/YYYY HH:mm a');
    }
  },
  useUpperCase: (data) => {
    let newData = {};
    Object.keys(data).forEach((k) => {
      if (typeof data[k] === 'string') {
        newData[k] = data[k].toUpperCase();
      } else {
        newData[k] = data[k];
      }
    });

    return newData;
  },
  isNilOrEmpty: (value) => {
    return isNil(value) || isEmpty(value);
  },
  validToken: (token) => {
    try {
      let timestamp = Math.floor((new Date()).getTime() / 1000);
      let expiresOn = Math.floor((new Date(token.expiresOn)).getTime() / 1000);

      return expiresOn > timestamp;
    } catch(e) {
      return false;
    }
  }
}

export default helpers;
