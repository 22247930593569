import React from "react";
import {Route, Switch, withRouter} from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route exact path={`${match.url}`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./Dashboard'))}/>
      <Route exact path={`${match.url}generales/cuentas`} component={asyncComponent(() => import('./Generales/Cuentas'))}/>
      <Route path={`${match.url}generales/cuentas/:cuentaId`} component={asyncComponent(() => import('./Generales/Cuentas/Detalle'))}/>
      <Route exact path={`${match.url}gestion-at/activos`} component={asyncComponent(() => import('./GestionAT/Activos'))}/>
      <Route path={`${match.url}gestion-at/activos/:activoId`} component={asyncComponent(() => import('./GestionAT/Activos/Detalle'))}/>
      <Route exact path={`${match.url}gestion-at/casos`} component={asyncComponent(() => import('./GestionAT/Casos'))}/>
      <Route path={`${match.url}gestion-at/casos/:casoId`} component={asyncComponent(() => import('./GestionAT/Casos/Detalle'))}/>
      <Route exact path={`${match.url}gestion-at/orden-trabajo`} component={asyncComponent(() => import('./GestionAT/OrdenTrabajo'))}/>
      <Route path={`${match.url}gestion-at/orden-trabajo/:ordenId`} component={asyncComponent(() => import('./GestionAT/OrdenTrabajo/Detalle'))}/>
      <Route exact path={`${match.url}gestion-comercial/cierre-negocios`} component={asyncComponent(() => import('./GestionComercial/CierreNegocio'))}/>
      <Route path={`${match.url}gestion-comercial/cierre-negocios/:cierreId`} component={asyncComponent(() => import('./GestionComercial/CierreNegocio/Detalle'))}/>
      <Route exact path={`${match.url}gestion-comercial/cotizaciones`} component={asyncComponent(() => import('./GestionComercial/Cotizaciones'))}/>
      <Route path={`${match.url}gestion-comercial/cotizaciones/:cotizacionId`} component={asyncComponent(() => import('./GestionComercial/Cotizaciones/Detalle'))}/>
      <Route exact path={`${match.url}gestion-comercial/compras`} component={asyncComponent(() => import('./GestionComercial/Compras'))}/>
      <Route path={`${match.url}gestion-comercial/compras/:compraId`} component={asyncComponent(() => import('./GestionComercial/Compras/Detalle'))}/>
      <Route path={`${match.url}gestion-comercial/inventarios`} component={asyncComponent(() => import('./GestionComercial/ConsultaInventario'))}/>
      <Route path={`${match.url}gestion-comercial/lista-precios`} component={asyncComponent(() => import('./GestionComercial/ListaPrecios'))}/>
      <Route exact path={`${match.url}gestion-comercial/visitas`} component={asyncComponent(() => import('./GestionComercial/Visitas'))}/>
      <Route path={`${match.url}gestion-comercial/visitas/:visitaId`} component={asyncComponent(() => import('./GestionComercial/Visitas/Detalle'))}/>
    </Switch>
  </div>
);

export default withRouter(App);
