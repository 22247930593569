module.exports = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID ? process.env.REACT_APP_MSAL_CLIENT_ID: "bb8280f5-4eec-4ef3-b5b3-f5aa343bf5a5",
    authority: "https://login.microsoftonline.com/emasal.com",
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URI ?  process.env.REACT_APP_MSAL_REDIRECT_URI: "http://localhost:3000/"
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  },
  graphConfig: {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  },
  // create a request object for login or token request calls
  // In scenarios with incremental consent, the request object can be further customized
  requestObj: {
    scopes: ["user.read"]
  }
}
