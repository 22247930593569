import React from "react";
import { Form, Row, Col} from "antd";
import {connect} from "react-redux";
import msalConfig from "util/msal";
import { UserAgentApplication } from 'msal';
import {
  setUser,
  userSignOut,
  userSignedIn
} from "appRedux/actions/Auth";
import { getUserDetails } from 'util/GraphService';
import axios from "util/Api";
import { apiUrl } from "util/config";

class SignIn extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
      error: null,
    };

    this.userAgentApplication = new UserAgentApplication({
      auth: {
        clientId: msalConfig.auth.clientId,
        authority: msalConfig.auth.authority
      },
      cache: msalConfig.cache,
    });

    let user = this.userAgentApplication.getAccount();
    if (user) {
      // Enhance user object with data from Graph
      this.getUserProfile();
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.login();
  };

  componentDidUpdate() {
    if (this.props.token !== null && this.state.isLoggedIn) {
      this.props.history.push('/');
    }
  }

  render() {
    const { error } =  this.state;

    return (
      <div className="gx-app-login-wrap">
        <Row type="flex" justify="center">
          <Col md={10} sm={16} xs={24} lg={8}>
            <div className="custom-login-content">
              <Row type="flex">
                <Col span={24}>
                  <div className="custom-login-logo">
                    <img src={require("assets/images/Emasal(FondoBlanco).png")} alt="EMSAL"/>
                  </div>
                </Col>
              </Row>
              <Row type="flex">
                <Col span={24} style={{textAlign: "center"}}>
                  <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                    <button className="custom-login-btn" type="submit">
                      <img src={require("assets/images/ms-logo.png")} alt="logo"/>
                      <span>Iniciar sessi&oacute;n con Microsoft</span>
                    </button>
                  </Form>
                </Col>
              </Row>
              {error && <Row type="flex">
                <Col span={24} style={{textAlign: "center"}} className="login-errors">
                  <span>
                    {error.message}
                  </span>
                </Col>
              </Row>}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  setErrorMessage(message, debug) {
    this.setState({
      error: {message: message, debug: debug}
    });
  }

  async login() {
    try {
      await this.userAgentApplication.loginPopup(
        {
          scopes: msalConfig.requestObj.scopes,
          prompt: "select_account"
      });
      await this.getUserProfile();
    }
    catch(err) {
      console.log(err)
      var error = {};

      if (typeof(err) === 'string') {
        var errParts = err.split('|');
        error = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
        };
      }
      this.props.userSignOut();
      this.setState({
        isLoggedIn: false,
        user: {},
        error: error
      });
      setTimeout(() => {
        this.userAgentApplication.logout();
      }, 2000);
    }
  }

  logout() {
    this.userAgentApplication.logout();
  }

  async getUserProfile() {
    try {
      // Get the access token silently
      // If the cache contains a non-expired token, this function
      // will just return the cached token. Otherwise, it will
      // make a request to the Azure OAuth endpoint to get a token

      var accessToken = await this.userAgentApplication.acquireTokenSilent({
        scopes: msalConfig.requestObj.scopes,
      });

      if (accessToken) {
        // Get the user's profile from Graph
        this.props.userSignedIn(accessToken);
        let user = await getUserDetails(accessToken);
        const filter = JSON.stringify({ CORREO: user.mail, ACTIVO: 'S' });
        const res = await axios.get(`${apiUrl}/public/users/findOne?filters=${filter}&log=true`);
        if (!res.data.cliente) throw new Error("Usuario no existe o inactivo!");
        user['urlDashboard'] = res.data.cliente.URL_DASHBOARD;
        user['urlDashboard2'] = res.data.cliente.URL_DB2;
        user['urlDashboard3'] = res.data.cliente.URL_DB3;
        user['user_id'] = res.data.cliente.idUSUARIO;
        user['perfil'] = res.data.cliente.PERFIL;
        user['pais'] = res.data.cliente.PAIS;
        user['nombres'] = res.data.cliente.NOMBRES;
        user['apellidos'] = res.data.cliente.APELLIDOS;
        user['avatar'] = res.data.cliente.URL_FOTO;

        this.props.setUser(user);
        this.setState({
          isLoggedIn: true,
          user: user,
          error: null
        })
      } else {
        throw new Error("Usuario no ha iniciado session!");
      }
    }
    catch(err) {
      console.log(err)
      var error = {};
      if (typeof(err) === 'string') {
        var errParts = err.split('|');
        error = errParts.length > 1 ?
          { message: errParts[1], debug: errParts[0] } :
          { message: err };
      } else {
        error = {
          message: err.message,
          debug: JSON.stringify(err)
        };
      }
      this.props.userSignOut();
      this.setState({
        isLoggedIn: false,
        user: {},
        error: error
      });
      setTimeout(() => {
        this.userAgentApplication.logout();
      }, 2000);
    }
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser, token} = auth;
  return {loader, alertMessage, showMessage, authUser, token}
};

export default connect(mapStateToProps, {
  setUser,
  userSignOut,
  userSignedIn
})(WrappedNormalLoginForm);
