var graph = require('@microsoft/microsoft-graph-client');

function getAuthenticatedClient(accessToken) {
  // Initialize Graph client
  const client = graph.Client.init({
    // Use the provided access token to authenticate
    // requests
    authProvider: (done) => {
      done(null, accessToken.accessToken);
    }
  });

  return client;
}

export async function getUserPhoto(accessToken) {
  const client = getAuthenticatedClient(accessToken);
  try {
    return await client.api('/me/photo/$value').get();
  } catch (e) {
    console.log(e)
    return null;
  }
}

export async function getUserDetails(accessToken) {
  const client = getAuthenticatedClient(accessToken);
  const user = await client.api('/me').get();

  return user;
}
