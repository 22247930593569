import React, {Component} from "react";
import {connect} from "react-redux";
import URLSearchParams from 'url-search-params'
import {
  HashRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import {ConfigProvider} from "antd";
import {IntlProvider} from "react-intl";
import msalConfig from "util/msal";
import { UserAgentApplication } from 'msal';
import { userSignOut } from "appRedux/actions/Auth";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import helpers from "util/helpers";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting";

const RestrictedRoute = ({component: Component, token, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      token
        ? <Component {...props} />
        : <Redirect
            push
            to={{
              pathname: '/signin',
              state: {from: props.location}
            }}
        />}
  />;

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
      tokenExpired: true,
    };
  }

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.location.pathname === "/signin") return null;

    let userAgentApplication = new UserAgentApplication({
      auth: {
        clientId: msalConfig.auth.clientId
      },
      cache: msalConfig.cache,
    });

    var user = userAgentApplication.getAccount();

    if (!user || nextProps.token === null || !helpers.validToken(nextProps.token))  {
      nextProps.userSignOut();
      return { isLoggedIn: false }
    }

    return null;
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
  }

  render() {
    const {match, location, themeType, layoutType, navStyle, locale, token } = this.props;
    const { isLoggedIn } = this.state;

    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme');
    }
    if (location.pathname === '/' && isLoggedIn) {
      return ( <Redirect push to={'/dashboard'}/> );
    }

    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ConfigProvider locale={currentAppLocale.antd}>
        <IntlProvider

          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <Router>
            <Switch>
              <Route exact path='/signin' component={SignIn}/>
              <RestrictedRoute path={`${match.url}`} token={token}
                               component={MainApp}/>
            </Switch>
          </Router>
        </IntlProvider>
      </ConfigProvider>
    )
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {locale, navStyle, themeType, layoutType} = settings;
  const {token} = auth;
  return {locale, token, navStyle, themeType, layoutType}
};
export default connect(mapStateToProps, { userSignOut, setThemeType, onNavStyleChange, onLayoutTypeChange})(App);
