import React, {Component} from "react";
import {connect,} from "react-redux";
import {Menu} from "antd";
import {Link, withRouter} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;

class SidebarContent extends Component {

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (<Auxiliary>

        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`} style={{border: 'none'}}>
            <UserProfile/>
            {/*<AppsNavigation/>*/}
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">

              <Menu.Item key="dashboard">
                <Link to="/dashboard"><i className="icon icon-dasbhoard"/>
                  <IntlMessages id="sidebar.dashboard"/></Link>
              </Menu.Item>


              <SubMenu key="generales" className={this.getNavStyleSubMenuClass(navStyle)}
                       title={<span> <i className="icon icon-inbox"/>
                       <IntlMessages id="sidebar.generales"/></span>}>

                 <Menu.Item key="generales/cuentas">
                   <Link to="/generales/cuentas">
                     <i className="icon icon-wall"/>
                     <IntlMessages id="sidebar.generales.cuentas"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="generales/contactos">
                   <Link to="/sample">
                     <i className="icon icon-contacts"/>
                     <IntlMessages id="sidebar.generales.contactos"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="generales/direcciones">
                   <Link to="/sample">
                     <i className="icon icon-map-simple"/>
                     <IntlMessages id="sidebar.generales.direcciones"/>
                   </Link>
                 </Menu.Item>

              </SubMenu>

              <SubMenu key="gestionComercial" className={this.getNavStyleSubMenuClass(navStyle)}
                       title={<span> <i className="icon icon-revenue-new"/>
                       <IntlMessages id="sidebar.gestionComercial"/></span>}>

                 <Menu.Item key="gestionComercial/cierreNegocios">
                   <Link to="/gestion-comercial/cierre-negocios">
                     <i className="icon icon-check-square-o"/>
                     <IntlMessages id="sidebar.gestionComercial.cierreNegocios"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="gestionComercial/candidatos">
                   <Link to="/sample">
                     <i className="icon icon-user"/>
                     <IntlMessages id="sidebar.gestionComercial.candidatos"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="gestionComercial/cotizaciones">
                   <Link to="/gestion-comercial/cotizaciones">
                     <i className="icon icon-select"/>
                     <IntlMessages id="sidebar.gestionComercial.cotizaciones"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="gestionComercial/compras">
                   <Link to="/gestion-comercial/compras">
                     <i className="icon icon-shopping-cart"/>
                     <IntlMessages id="sidebar.gestionComercial.compras"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="gestionComercial/visitas">
                   <Link to="/gestion-comercial/visitas">
                     <i className="icon icon-map-street-view" / >
                     <IntlMessages id="sidebar.gestionComercial.visitas"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="gestionComercial/demos">
                   <Link to="/sample">
                     <i className="icon icon-check-square-o"/>
                     <IntlMessages id="sidebar.gestionComercial.demos"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="gestionComercial/inventarios">
                   <Link to="/gestion-comercial/inventarios">
                     <i className="icon icon-product-list"/>
                     <IntlMessages id="sidebar.gestionComercial.consultaInventarios"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="gestionComercial/listaPrecios">
                   <Link to="/gestion-comercial/lista-precios">
                     <i className="icon icon-product-list"/>
                     <IntlMessages id="sidebar.gestionComercial.listaPrecios"/>
                   </Link>
                 </Menu.Item>

              </SubMenu>


              <SubMenu key="gestionAt" className={this.getNavStyleSubMenuClass(navStyle)}
                       title={<span> <i className="icon icon-extra-components"/>
                       <IntlMessages id="sidebar.gestionAt"/></span>}>

                 <Menu.Item key="gestionAt/controlActivos">
                   <Link to="/gestion-at/activos">
                     <i className="icon icon-chart-radar"/>
                     <IntlMessages id="sidebar.gestionAt.controlActivos"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="gestionAt/casos">
                   <Link to="/gestion-at/casos">
                     <i className="icon icon-chat-new"/>
                     <IntlMessages id="sidebar.gestionAt.casos"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="gestionAt/ordenTrabajo">
                   <Link to="/gestion-at/orden-trabajo">
                     <i className="icon icon-ckeditor"/>
                     <IntlMessages id="sidebar.gestionAt.ordenTrabajo"/>
                   </Link>
                 </Menu.Item>

              </SubMenu>

              <SubMenu key="configuracion" className={this.getNavStyleSubMenuClass(navStyle)}
                       title={<span> <i className="icon icon-components"/>
                       <IntlMessages id="sidebar.configuracion"/></span>}>

                 <Menu.Item key="configuracion/usuarios">
                   <Link to="/sample">
                     <i className="icon icon-auth-screen"/>
                     <IntlMessages id="sidebar.configuracion.usuarios"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="configuracion/perfiles">
                   <Link to="/sample">
                     <i className="icon icon-family"/>
                     <IntlMessages id="sidebar.configuracion.perfiles"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="configuracion/paises">
                   <Link to="/sample">
                     <i className="icon icon-geo-location"/>
                     <IntlMessages id="sidebar.configuracion.paises"/>
                   </Link>
                 </Menu.Item>

                 <Menu.Item key="configuracion/privilegios">
                   <Link to="/sample">
                     <i className="icon icon-ckeditor"/>
                     <IntlMessages id="sidebar.configuracion.privilegios"/>
                   </Link>
                 </Menu.Item>

              </SubMenu>

              <Menu.Item key="sample">
                <Link to="/sample"><i className="icon icon-dasbhoard"/>
                  <IntlMessages id="app.userAuth.logOut"/></Link>
              </Menu.Item>

            </Menu>
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
  const {navStyle, themeType, locale, pathname} = settings;
  return {navStyle, themeType, locale, pathname}
};
export default withRouter(connect(mapStateToProps)(SidebarContent));
